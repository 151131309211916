<template>
  <div class="AdminStages">
    <div class="StagesWrapper">
      <div class="header">
        <h1>Управление этапами проектов</h1>
        <button @click="stageCreate()">Добавить этап</button>
        <button @click="openProjectModerationWindow = true">
          Этапы и шаги пользователей
        </button>
      </div>
      <div class="StagesList">
        <details
          class="Stage"
          v-for="(Stage, index) in StagesList"
          :key="Stage + StageOpenedID"
          :open="StageOpenedID == Stage.id ? true : false"
          @click="openStage($event, Stage.id)"
        >
          <summary class="StageHeader">
            <span class="StageHeader">{{
              `Этап ${index + 1}: ${Stage.name}`
            }}</span>
            <button @click="stepCreate(Stage.id)">Добавить шаг</button>
            <button @click="stageDelete(Stage.id)">Удалить этап</button>
          </summary>
          <div class="StageSteps">
            <div class="StageTitle">
              <span
                class="StepTitle"
                style="display: flex; flex-direction: row; "
              >
                <span> {{ `Название Этапа :` }}</span>
                <p
                  contenteditable="true"
                  @focusout="updateStageTitle($event, Stage.id)"
                >
                  {{ Stage.name }}
                </p>
              </span>
            </div>

            <div
              class="Step"
              v-for="(Step, stepIndex) in sortStages(Stage.steps)"
              :key="Step"
            >
              <span class="StepTitle">
                <span> {{ `Шаг ${stepIndex + 1}:` }}</span>
                <p
                  contenteditable="true"
                  @focusout="updateStep($event, Step.id, 'title')"
                >
                  {{ `${Step.name}` }}
                </p>
              </span>

              <p
                class="StepDesc"
                contenteditable="true"
                @focusout="updateStep($event, Step.id, 'desc')"
              >
                {{ Step.description }}
              </p>

              <div class="StepButtons">
                <span class="StepCheckbox">
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    :checked="Step.active"
                    @change="updateStep($event, Step.id, 'active')"
                  />
                  <label for="scales">Активно</label>
                </span>

                <span>
                  <label for="tentacles">Ранг:</label>
                  <input
                    type="number"
                    :value="Step.rank"
                    min="0"
                    max="100"
                    @change="updateStep($event, Step.id, 'rank')"
                  />
                </span>

                <button @click="stepDelete(Step.id)">Удалить</button>
              </div>
            </div>
          </div>
        </details>
      </div>
    </div>

    <el-dialog v-model="openProjectModerationWindow" title="Tips" width="30%">
      <h3>Поиск пользователя</h3>
      <el-select
        v-model="UserInputValue"
        filterable
        placeholder="Выбор пользователя"
      >
        <el-option
          v-for="User in UsersList"
          :key="`${User.name} ${User.surname}`"
          :label="`${User.name} ${User.surname}`"
          :value="`${User.name} ${User.surname}`"
          @click="SelectedUser = User"
        />
        <el-option
          v-for="User in UsersList"
          :key="User.email"
          :label="User.email"
          :value="User.email"
          @click="SelectedUser = User"
        />
        <el-option
          v-for="User in UsersList"
          :key="User.id"
          :label="User.id"
          :value="User.id"
          @click="SelectedUser = User"
        />
      </el-select>

      <template v-if="SelectedUser != null">
        <h5>Дата последнего входа: {{ getLastLoginDate(SelectedUser) }}</h5>
        <h5>Выбран проект: {{ getActiveProject }}</h5>
        <h3>Активный этап</h3>

        <el-select
          v-model="setActiveStage"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in setStagesList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>

        <h3>Отметить выполнение шагов</h3>

        <div class="UserSteps">
          <el-checkbox
            v-for="(step, index) in getStepsForThisStage"
            :key="step"
            v-model="step.checked"
            :label="`Шаг ${index + 1}: ${step.name}`"
            size="large"
            border
            @click="actionWithUserStep($event, step.id)"
          />
        </div>
      </template>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openProjectModerationWindow = false"
            >Закрыть</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.UserSteps {
  position: relative;
  display: flex;

  flex-direction: column;
}
.AdminStages {
  position: relative;
  display: flex;

  width: 100%;
  min-height: 90vh;
  margin-top: 50px;
}

.AdminStages > .StagesWrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 90%;
  height: auto;

  margin: 0% auto;
}
.StagesWrapper > .header > h1 {
  color: #ffffff;
}

.StagesWrapper > .header {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.StagesWrapper > .header > button {
  position: relative;
  margin-left: 40px;
}

.StagesWrapper > .StagesList {
  position: relative;
  width: fit-content;
  height: auto;

  display: flex;
  flex-direction: column;

  margin: 0% auto;
}

.StagesList > .Stage {
  position: relative;

  width: 600px;
  height: fit-content;

  margin: 10px;
}

.Stage > summary {
  position: relative;

  display: flex;
  flex-direction: row;

  background-color: white;

  padding: 24px;

  justify-content: left;
}

.Stage > summary > span {
  position: relative;
  margin-left: 10px;
  margin-right: auto;
}
.Stage > summary > button {
  position: relative;
}

.Stage[open] > summary::before {
  content: "\25b2";
}

.Stage[open] > summary {
  padding-bottom: 24px;
  border-bottom: 1px solid black;
}

.Stage > summary::before {
  content: "\25bc";
}

.StagesList > .StageSteps {
  position: relative;

  display: flex;
  flex-direction: column;
}

.StageSteps > .Step,
.StageSteps > .StageTitle {
  position: relative;

  text-align: justify;

  margin: 12px auto;
  width: 90%;

  display: flex;
  flex-direction: column;

  background-color: white;

  border-radius: 12px;

  padding: 12px;
}

.Step > .StepTitle {
  position: relative;
  display: flex;
  flex-direction: row;

  font-size: large;
  font-weight: 600;
}
.Step > .StepDesc {
  position: relative;

  font-size: medium;
  font-weight: 500;
}
.Step > .StepButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Step > .StepButtons > * {
  margin-bottom: auto;
  margin-top: auto;
}
</style>

<script>
// import Sidebar from "@/components/Profile/Sidebar.vue";

import {
  createStage,
  updateStage,
  deleteStage,
  getAllStages,
} from "@/api/stage.js";

import {
  createStageStep,
  updateStageStep,
  deleteStageStep,
  getUsersList,
  getAllSteps,
  addStepsToUser,
  removeStepsFromUser,
  findAllUserSteps,
} from "@/api/stage_step.js";

export default {
  name: "AdminStages",
  data() {
    return {
      StagesList: [],
      StageOpenedID: null,

      UsersList: [],
      SelectedUser: null,
      UserInputValue: null,

      setStagesList: [],
      setStepsList: [],

      setActiveStage: "",

      UserStepsForStage: [],
      UserReadySteps: [],

      openProjectModerationWindow: false,
    };
  },
  components: {},
  computed: {
    getActiveProject() {
      if (this.SelectedUser == null) {
        return "";
      }

      if (this.SelectedUser.user_offer.length > 0) {
        return this.SelectedUser.user_offer[0].name;
      }
      if (this.SelectedUser.OProjects.length > 0) {
        return this.SelectedUser.OProjects[0].name;
      }
      if (this.SelectedUser.Subscriptions.length > 0) {
        return this.SelectedUser.Subscriptions[0].name;
      }

      return "";
    },

    getStepsForThisStage() {
      let result = [];
      let userSteps = [];

      if (this.SelectedUser == null) {
        return [];
      }

      this.getUserReadySteps();

      for (let step of this.setStepsList) {
        if (step.stage.id == this.setActiveStage) {
          let stepReady = this.UserReadySteps.find((obj) => {
            return obj.id === step.id;
          });
          step.checked = stepReady == null ? false : true;
          result.push(step);
        }
      }

      this.UserStepsForStage = result;

      return result;
    },
  },
  async mounted() {
    await this.getStages();
    console.log(this.StagesList);

    let res = await getUsersList();
    this.UsersList = res.data;
    console.log(res);

    getAllSteps().then((res) => {
      let Steps = res.data;

      this.setStagesList = [];
      this.setStepsList = Steps;

      for (let Step of Steps) {
        let stageExist = this.setStagesList.find((obj) => {
          return obj.id === Step.stage.id;
        });

        if (stageExist == null) {
          this.setStagesList.push(Step.stage);
        }
      }
    });
  },
  methods: {
    getLastLoginDate(User) {
      if (User == null || User?.last_login == null) {
        return "";
      }

      return User.last_login
        .split("T")[0]
        .split("-")
        .reverse()
        .join(".");
    },
    openStage(event, stageID) {
      if (
        event.target.className != "StageHeader" &&
        event.target.className != "Stage"
      ) {
        return;
      }

      console.log(event);

      if (this.StageOpenedID == stageID) {
        this.StageOpenedID = null;
        return;
      }

      this.StageOpenedID = stageID;
    },

    sortStages(stepsList) {
      let sortedList = stepsList;
      sortedList.sort((a, b) => b.rank - a.rank);
      return sortedList;
    },

    async updateStageTitle(event, id) {
      await this.stageUpdate(id, {
        name: event.target.innerText,
      });
    },
    async getStages() {
      let AllStages = await getAllStages();
      this.StagesList = AllStages.data;
    },
    async stageDelete(id) {
      await deleteStage(id);
      await this.getStages();
    },
    async stageCreate() {
      await createStage({
        name: "Без названия",
        rank: 0,
        active: true,
      });
      await this.getStages();
    },
    async stageUpdate(id, data) {
      await updateStage(id, data);
      await this.getStages();
    },

    async stepCreate(StageID) {
      await createStageStep({
        name: "Без названия",
        description: "Пусто",
        rank: 0,
        active: false,
        stage_id: StageID,
      });
      await this.getStages();
    },
    async stepDelete(id) {
      await deleteStageStep(id);
      await this.getStages();
    },
    async updateStep(event, id, type) {
      let obj = {};

      switch (type) {
        case "title": {
          obj.name = event.target.innerText;
          break;
        }
        case "desc": {
          obj.description = event.target.innerText;
          break;
        }
        case "active": {
          obj.active = event.target.checked;
          break;
        }
        case "rank": {
          obj.rank = event.target.valueAsNumber;
          break;
        }
        default: {
          return;
        }
      }

      await updateStageStep(id, obj);
      await this.getStages();
    },

    async actionWithUserStep(checkValue, step) {
      if (checkValue?.target?.checked == null) {
        return;
      }
      let checked = checkValue.target.checked;

      if (checked == true) {
        await this.addStepsToUser(step);
      } else {
        await this.removeStepsToUser(step);
      }
    },

    async addStepsToUser(step) {
      let steps = [];
      let obj = {};

      steps.push(step);
      obj.steps = steps;

      await addStepsToUser(this.SelectedUser.id, obj);
    },
    async removeStepsToUser(step) {
      let steps = [];
      let obj = {};

      steps.push(step);
      obj.steps = steps;

      await removeStepsFromUser(this.SelectedUser.id, obj);
    },

    async getUserReadySteps() {
      let request = await findAllUserSteps(this.SelectedUser.id);
      this.UserReadySteps = request.data.StageStepUsers;
    },
  },
};
</script>
