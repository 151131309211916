import request from "@/api/config.js";

export function updateStage(id, data) {
  return request({
    url: `stage/${id}`,
    method: "put",
    data,
  });
}

export function createStage(data) {
  return request({
    url: `stage/create`,
    method: "post",
    data,
  });
}

export function deleteStage(id) {
  return request({
    url: `stage/${id}`,
    method: "delete",
  });
}

export function getAllStages() {
  return request({
    url: `stage/`,
    method: "get",
  });
}
